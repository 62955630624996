import React, {useState} from "react";
import {Form, redirect, useActionData, useNavigation} from "react-router-dom";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";
import {addMember} from "../../services/ledgerService";
import {InputGroup} from "react-bootstrap";

const AddMemberForm = () => {
    const [newMemberName, setNewMemberName] = useState('')
    const navigation = useNavigation();
    const errors = useActionData();
    const isSubmitPending = navigation.state === "submitting"
    return (
        <Form method="post" action={"/members"} onSubmit={() => setNewMemberName("")}>
            <InputGroup>
                <FormControl
                    value={newMemberName}
                    onChange={(e) => setNewMemberName(e.target.value)}
                    required={true}
                    name="name"
                    placeholder="Member name..."
                />
                <Button
                    type={"submit"}
                    variant="outline-success"
                    disabled={isSubmitPending}
                >
                    {isSubmitPending ? "Saving..." : "Add"}
                </Button>
            </InputGroup>
            {errors && <div className="text-danger ms-1">{errors.message}</div>}
        </Form>
    )
};

export default AddMemberForm;

export const action = async ({request}) => {
    const {...payload} = Object.fromEntries(await request.formData())
    try {
        await addMember(payload)
    } catch (e) {
        return {message: e.message}
    }
    return redirect("/members")
}