import {OverlayTrigger, Tooltip} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import React from "react";

export const IconTooltipButton = ({icon, tooltipMessage, ...props}) => {
    return (
        <OverlayTrigger
            placement={"bottom"}
            delay={{show: 250, hide: 400}}
            overlay={<Tooltip>{tooltipMessage}</Tooltip>}
        >
            <Button type={"button"} {...props} variant="success" className="btn-circle">{icon}</Button>
        </OverlayTrigger>
    );
}