import Stack from "react-bootstrap/Stack";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import React, {useState} from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import {Form, useRouteLoaderData} from "react-router-dom";
import FormControl from "react-bootstrap/FormControl";
import AddMemberForm from "./AddMemberForm";

export default function Members() {
    const { house } = useRouteLoaderData("root")

    const memberCards = house.members.map((m, i) => (
        <MemberCard key={i} member={m} />
    ));

    return (
        <Row>
            <Col xs={12} md={5} className="mb-2 order-md-2">
                {<AddMemberForm />}
            </Col>
            <Col xs={12} md={7} className="mx-auto mx-md-0">
                <Stack gap={3}>
                    {memberCards}
                </Stack>
            </Col>
        </Row>
    );
};

const MemberCard = ({ member }) => {
    const [editing, setEditing] = useState(false)
    const enableEditing = () =>  setEditing(true)
    const disableEditing = () => setEditing(false)

    const nameElement = editing
        ? <FormControl type="text" placeholder={member.name}/>
        : member.name
    const buttonElement = editing
        ? <Button onClick={disableEditing}>Submit</Button>
        : <Button disabled onClick={enableEditing}>Edit</Button>
    return (
        <Card className={"member-card"}>
            <Card.Body>
                <Form method={"patch"} action={`${member.id}`}>
                    <Row className={"align-items-center justify-content-between"}>
                        <Col xs={2} sm={4}><Image src={`https://i.pravatar.cc/50?u=${member.id}`} roundedCircle></Image></Col>
                        <Col xs={6} sm={4}>{nameElement}</Col>
                        <Col xs="auto" sm="auto" className="md-auto">{buttonElement}</Col>
                    </Row>
                </Form>
            </Card.Body>
        </Card>
    );
}

