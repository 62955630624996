import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import React from "react";
import {Link} from "react-router-dom";

export default function Landing() {
    return (
        <Row>
            <Card>
                <Card.Header as="h5">TODO: This is the landing page</Card.Header>
                <Card.Body>
                    <Card.Title>A new house needs to be set up.</Card.Title>
                    <Card.Text>
                        We should never see this in production...
                    </Card.Text>
                    <Link to={"/login"}>
                        <Button variant="primary">Log In</Button>
                    </Link>
                </Card.Body>
            </Card>
        </Row>
    );
}