import {FormCheck, Modal} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import React, {useState} from "react";
import {addMember} from "../../services/ledgerService";

export const AddPlayerModal = ({addMemberState, members, players, show, handleClose, handleChange}) => {
    const [mode, updateMode] = useState("editPlayers")
    const toggleMode = () => {
        updateMode(mode === "editPlayers" ? "addMember" : "editPlayers")
    }
    const activatePlayerMode = () => updateMode("editPlayers")
    const isPlayerMode = mode === "editPlayers";
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header className="justify-content-between">
                {isPlayerMode
                    ? <span>Select players for this game</span>
                    : <span>Create a new member</span>
                }
                <Button
                    variant="success"
                    onClick={toggleMode}>
                    {isPlayerMode ? "Add Member" : "Cancel"}
                </Button>
            </Modal.Header>
            <Modal.Body>
                {isPlayerMode
                    ? <EditPlayersForm
                        members={members}
                        players={players}
                        handleChange={handleChange}
                        handleClose={handleClose} />
                    : <AddMemberForm
                        activatePlayerMode={activatePlayerMode}
                        members={members}
                        addMemberState={addMemberState}
                    />
                }
            </Modal.Body>
        </Modal>
    );
};

const EditPlayersForm = ({ members, players, handleChange, handleClose }) => {
    const playerIds = players.map(p => p.id)
    const memberChecks = members.map((m, i) => {
        const isMemberPlayer = playerIds.includes(m.id)
        return (<FormCheck
            type="switch"
            key={i}
            disabled={isMemberPlayer && players.find(p => p.id === m.id).transactions.length > 0}
            value={`${m.id}`}
            label={m.name}
            checked={isMemberPlayer}
            onChange={handleChange}
        />);
    });
    return (
       <Form>
           <Row className="justify-content-between">
               <Col xs={6} className="v-scrollable">
                   <Form.Group>
                       {memberChecks}
                   </Form.Group>
               </Col>
               <Col xs="auto" className="d-flex flex-column justify-content-end">
                   <Button type="button" onClick={handleClose}>Done</Button>
               </Col>
           </Row>
       </Form>
   )
};

const AddMemberForm = ({ addMemberState, activatePlayerMode, members }) => {
    const [error, setError] = useState(null);
    const onSubmit = async (e) => {
        e.preventDefault();
        const form = e.target
        const data = Array.from(form.elements)
            .filter((i) => i.name)
            .reduce((acc, i) => Object.assign(acc, {[i.name]: i.value}), {});
        try {
            const member = await addMember(data);
            addMemberState(member)
            activatePlayerMode();
        } catch (e) {
            setError(e.message)
        }
    }
    return (
        <Form onSubmit={onSubmit}>
            <Row>
                <Col xs={9}>
                    <Form.Control
                        required={true}
                        name="name"
                        placeholder="Member name..."
                        className="mb-2"
                    />
                    {error && <span className="text-danger ms-1">{error}</span>}
                </Col>
                <Col xs="auto">
                    <Button type="submit">Submit</Button>
                </Col>
            </Row>
    </Form>);
}