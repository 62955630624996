import {useState} from "react";

export const useForm = (service, stateUpdater) => {
    const [status, setStatus] = useState('')
    const [message, setMessage] = useState('')

    const handleSubmit = (e) => {
        e.preventDefault()
        setStatus('loading')
        setMessage('')

        const form = e.target
        const data = Array.from(form.elements)
            .filter((i) => i.name)
            .reduce((acc, i) => Object.assign(acc, {[i.name]: i.value}), {});

        return service({...data})
            .then(result => {
                console.log("useForm service.then", { result })
                setStatus('success')
                stateUpdater(result)
            }).catch(err => {
                console.log("useForm service.catch", { err })
                setStatus('error')
                setMessage(err.message)
            });
    };

    return {handleSubmit, status, message}
}
