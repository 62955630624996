import React, {useContext, useState} from 'react';
import {addPlayer, fetchGame, getHouse, removePlayer} from '../../services/ledgerService'

import './Ledger.css';
import {redirect, useLoaderData, useRouteLoaderData} from "react-router-dom";
import {formatDate} from "../../utilities";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import {ButtonGroup, FormControl, InputGroup, ListGroup} from "react-bootstrap";
import Card from "react-bootstrap/Card";
import {Cash, PersonFillAdd, PersonX, Search, X,} from "react-bootstrap-icons";
import {AddPlayerModal} from "./AddPlayerModal";
import {AddTransactionModal} from "./AddTransactionModal";
import {IconTooltipButton} from "./IconTooltipButton";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import Form from "react-bootstrap/Form";

export function Ledger() {
    const { house } = useRouteLoaderData("root")
    const { game } = useLoaderData();
    const [members, updateMembers] = useState(house.members)
    const addMember = (member) => {
        updateMembers([...members, member])
    }

    const [players, updatePlayers] = useState(game.players)
    const addPlayerState = (player) => {
        updatePlayers([...players, player])
    }
    const removePlayerState = (playerId) => {
        const updatedPlayers = players.filter(p => p.id !== playerId)
        updatePlayers(updatedPlayers)
    }

    // add player modal controls
    const [showAddPlayer, setShowAddPlayer] = useState(false)
    const handleCloseAddPlayer  = () => (setShowAddPlayer(false))
    const handleShowAddPlayer  = () => (setShowAddPlayer(true))

    // add transaction modal controls
    const [showAddTransaction, setShowAddTransaction] = useState(false)
    const handleCloseAddTransaction = () => setShowAddTransaction(false)
    const handleShowAddTransaction = () => setShowAddTransaction(true)

    const [activePlayer, setActivePlayer] = useState(null);

    const addTransactionToPlayer = (playerId, txn) => {
        const playerIndex = players.findIndex(p => p.id === playerId)
        players[playerIndex].transactions.push(txn)
        updatePlayers(players)
    }

    const handlePlayerChange = async (e) => {
        const check = e.target;
        const memberId = check.value;
        const chosen = check.checked;

        if (chosen) {
            try {
                const player = await addPlayer({ gameId: game.id, memberId })
                addPlayerState(player)
            } catch (e) {
                console.error("handlePlayerChange.addPlayer", e)
            }
        } else {
            try {
                await removePlayer({gameId: game.id, playerId: memberId})
                removePlayerState(memberId)
            } catch (e) {
                console.error("handlePlayerChange.removePlayer", e.message)
            }
        }
    };

    const handlePlayerDelete = async (playerId) => {
        try {
            await removePlayer({ gameId: game.id, playerId })
            removePlayerState(playerId)
        } catch (e) {
            console.log("handlePlayerDelete", e.message)
        }
    }

    // Typeahead
    const [typeaheadInput, setTypeaheadInput] = useState("")
    const [typeaheadResults, setTypeaheadResults] = useState([])
    const [showTypeAheadResults, setShowTypeAheadResutls] = useState(false)
    const onTypeaheadChange = (e) => {
        const nameValue = e.target.value
        setShowTypeAheadResutls(true)
        setTypeaheadInput(nameValue)
        setTypeaheadResults(players.filter(p => p.name.toLowerCase().indexOf(typeaheadInput.toLowerCase()) > -1))
    };
    const onResultClick = (p) => (() => {
        setTypeaheadResults([p])
        setTypeaheadInput(p.name)
        setShowTypeAheadResutls(false)
    });
    const onResultClearClick = () => {
        setTypeaheadResults([])
        setTypeaheadInput("")
    }
    const viewablePlayers = typeaheadResults.length > 0 ? typeaheadResults : players

    const playerStats = viewablePlayers.map(p =>
        <PlayerStats
            key={p.id}
            player={p}
            handleDelete={handlePlayerDelete}
            handleShowAddTransaction={() => {
                setActivePlayer(p)
                handleShowAddTransaction()
            }} />
    );
    return (
        <>
            <Row className={"align-items-center"}>
                <Col xs={5}>
                    <h3 className={""}>{game.description}</h3>
                </Col>
                <Col xs={"auto"} className={"ms-auto"}>
                    <span className={"game-date text-muted"}>{formatDate(game.date)}</span>
                </Col>
                <Col xs={2}>
                    <IconTooltipButton tooltipMessage={"Add a player"} icon={<PersonFillAdd/>} onClick={handleShowAddPlayer} />
                </Col>
            </Row>
            <Row className={"game-table-header mt-3"}>
                <Col xs={3} md={4}><h3>Player</h3></Col>
                <Col xs={3} md={2}><h3>Buy-In</h3></Col>
                <Col xs={3} md={2}><h3>Cash-Out</h3></Col>
                <Col xs={3} md={2}><h3>Net</h3></Col>
                <Col xs={12} md="auto"></Col>
            </Row>
            <Row><hr/></Row>
            <Row className="mb-3">
                <Col>
                    <Form.Group>
                        <InputGroup>
                            <InputGroup.Text><Search/></InputGroup.Text>
                            <Form.Control
                                type="text"
                                onChange={onTypeaheadChange}
                                placeholder="search for a player..."
                                value={typeaheadInput}
                            />
                            {typeaheadInput.length > 0 && <InputGroup.Text onClick={onResultClearClick}><X/></InputGroup.Text>}
                        </InputGroup>
                        <ListGroup>
                            {showTypeAheadResults > 0 &&
                                typeaheadResults.map(r => (
                                    <ListGroup.Item
                                        key={r.id}
                                        onClick={onResultClick(r)}
                                    >
                                        {r.name}
                                    </ListGroup.Item>
                                ))
                            }
                        </ListGroup>
                    </Form.Group>
                </Col>
            </Row>
            {playerStats}
            <AddPlayerModal show={showAddPlayer} handleClose={handleCloseAddPlayer} handleChange={handlePlayerChange} addMemberState={addMember} members={members} players={players} />
            <AddTransactionModal gameId={game.id} player={activePlayer} show={showAddTransaction} handleClose={handleCloseAddTransaction} addTransactionToPlayer={addTransactionToPlayer} />
        </>
    )
}

export async function loader({ params }) {
    let game = null
    try {
        game = await fetchGame({gameId: params.gameId})
    } catch (e) {
        console.error('Error fetching house data:', e);
        return redirect("/login")
    }

    if (!game) {
        return redirect("/games");
    }

    return { game }
}

const transactionValue = (t) => {
    switch (t.type) {
        case 'BUY_IN':
        case 'ADD_ON':
            return -t.amount;
        case 'CASH_OUT':
            return t.amount;
        default:
            return 0;
    }
}

function PlayerStats({ player, handleDelete, handleShowAddTransaction }) {
    const [showTransactionList, setShowTransactionList] = useState(false)
    const playerHasTransactions = player.transactions.length > 0;
    const totals = player.transactions.reduce((acc, t) => ["BUY_IN", "ADD_ON"].includes(t.type)
            ? {...acc, buyIn: acc.buyIn + t.amount}
            : {...acc, cashOut: acc.cashOut + t.amount},
        { buyIn: 0, cashOut: 0}
    );

    const net = totals.cashOut - totals.buyIn
    const netColor = net >= 0 ? "text-success" : "text-danger"
    return (
        <Row>
            <Card className={"mb-3"}>
                <Card.Body onClick={() => setShowTransactionList(!showTransactionList)}>
                    <Row>
                        <Col xs={3} md={4} className="align-self-center"><span className={"fw-bolder"}>{player.name}</span></Col>
                        <Col xs={3} md={2} className="align-self-center">{totals.buyIn}</Col>
                        <Col xs={3} md={2} className="align-self-center">{totals.cashOut}</Col>
                        <Col xs={3} md={2} className={`align-self-center fw-bold ${netColor}`}>{net}</Col>
                        <Col xs={12} md={"auto"} className={"d-flex justify-content-center mt-3 mt-md-auto"}>
                            <ButtonGroup className="w-100">
                                <Button variant={"success"} onClick={handleShowAddTransaction}><Cash/></Button>
                                <Button disabled={playerHasTransactions} variant={"danger"} onClick={() => handleDelete(player.id)}><PersonX/></Button>
                            </ButtonGroup>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <TransactionRows transactions={player.transactions} showTransactions={showTransactionList} />
        </Row>
    );
}

function TransactionRows({ transactions, showTransactions }) {
    if (!showTransactions) {
        return null
    }

    let runningNet = 0;

    return transactions.map(t => {
        runningNet += transactionValue(t)

       let transactionElement = null;

        if (t.type === 'BUY_IN' || t.type === 'ADD_ON') {
            transactionElement = <BuyInTransaction key={t.id} transaction={t} netAmount={runningNet} />
        } else if (t.type === 'CASH_OUT') {
            transactionElement = <PayOutTransaction key={t.id} transaction={t} netAmount={runningNet} />
            runningNet = 0;
        }

        return transactionElement
    });
}

function BuyInTransaction({ transaction, netAmount }) {
    return (
        <Row>
            <Col xs={3} md={4}>{transaction.type}</Col>
            <Col xs={3} md={2}>{transaction.amount}</Col>
            <Col xs={3} md={2}></Col>
            <Col xs={3} md={2}>{netAmount}</Col>
            <Col xs={12} md={"auto"}></Col>
        </Row>
    )
}

function PayOutTransaction({ transaction, netAmount }) {
    return (
        <Row>
            <Col xs={3} md={4}>{transaction.type}</Col>
            <Col xs={3} md={2}></Col>
            <Col xs={3} md={2}>{transaction.amount}</Col>
            <Col xs={3} md={2}>{netAmount}</Col>
            <Col xs={12} md={"auto"}></Col>
        </Row>
    )
}