import React from 'react';
import Button from "react-bootstrap/Button";
import Card from 'react-bootstrap/Card';
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Stack from "react-bootstrap/Stack";
import LinkContainer from 'react-router-bootstrap/LinkContainer'

import {createGame} from '../../services/ledgerService'
import {useForm} from "../../UseForm";

import {formatDate} from "../../utilities";
import {useLoaderData, useRouteLoaderData} from "react-router-dom";

const House = () => {
    const { house } = useRouteLoaderData("root")
    return (
        <>
            <Row>
                <Col><h1>{house.description}</h1></Col>
            </Row>
            <Row className="mt-4">
                <Col>
                    {CreateGameForm({ house })}
                </Col>
            </Row>
            <Row className="mt-3">
                <Col sm={10} className={'mx-auto'}>
                    {GameList({ games: house.games })}
                </Col>
            </Row>
        </>
    );
};

const GameList = ({ games }) => {
    if (!games.length) {
        return null
    }

    return (
        <Stack gap={3}>
            {games.map(GameSummary)}
        </Stack>
    );
}

const GameSummary = ({ id, description, date, totalBuyIns, totalCashOuts, totalNet }) => {
    const formattedDate = formatDate(date);
    return (
        <LinkContainer key={id} to={`/games/${id}`}>
            <Card className={"p-3"}>
                <Row>
                    <Col><Card.Title>{description}</Card.Title></Col>
                    <Col className={'text-end'}><small className='text-muted'>{formattedDate}</small></Col>
                </Row>
                <Row>
                    <Col><label>Buy Ins:</label><span className='p-2'>{totalBuyIns}</span></Col>
                    <Col><label>Cash Outs:</label><span className='p-2'>{totalCashOuts}</span></Col>
                    <Col><label>Net:</label><span className='p-2'>{totalNet}</span></Col>
                </Row>
            </Card>
        </LinkContainer>
    );
};

const CreateGameForm = ({ house }) => {
    const stateUpdater = (result) => { house.games.push(result) }
    const { handleSubmit, status, message } = useForm(createGame, stateUpdater)
    return (
        <Form onSubmit={handleSubmit}>
            <Col>
                <Stack direction="horizontal" gap={3}>
                    <Form.Control required={true} name="description" className="" placeholder="Game descriiption..." />
                    <Form.Control required={true} name="date" className="" type="date" placeholder="Enter date..." />
                    <div className="vr" />
                    <Button type={"submit"} className="" variant="outline-success">Create Game</Button>
                </Stack>
            </Col>
            {status ? <Col xs={12}><span>{status}: {message}</span></Col> : null}
        </Form>
    )
}

export default House;